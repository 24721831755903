<template>
    <div class="haccp-bg pr">
        <div class="t-head jac">
            <h1>质控统计大屏</h1>
            <full-screen  style="position: absolute;right: 20px"></full-screen>
        </div>
        <div class="search-box">
            <el-date-picker
                    v-model="timeValue"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    unlink-panels
                    :picker-options="pickerOptions"
            ></el-date-picker>
            <div class="button" style="margin-left: 5px"  @click="getList()">筛选</div>
        </div>
        <el-row :gutter="20">
            <el-col
                    :span="12"
                    v-for="(item, index) in list"
                    :key="index"
                    style="margin-bottom: 5px;padding: 10px 20px"

            >
                <div class="box-border">
                    <div id="haccp-chart" >
                        <div class="bg-color-black">
                            <div class="d-flex pt-2 pl-2">
                                <span>
                                </span>
                                <div class="d-flex">
                                    <div class='box-title'>
                                        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">{{item.name}}</dv-decoration-11></span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <haccp-chart :list="item.haccpChartData" :index="index"/>
                            </div>
                        </div>
                    </div>
                </div>


            </el-col>
        </el-row>

        <el-dialog title="质控记录" :visible.sync="dialogTableVisible">
            <el-table :data="samplingBatchData" border height="800">
                <el-table-column
                        align="center"
                        property="name"
                        label="内部生产序号"
                ></el-table-column><el-table-column
                        align="center"
                        property="submitTime"
                        label="时间"
                ></el-table-column>
                <el-table-column
                        align="center"
                        property="submitName"
                        label="操作人员"
                ></el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click="toTrace(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog
                    top="5vh"
                    width="400px"
                    title="质控详情"
                    :visible.sync="innerVisible"
                    append-to-body
            >
            </el-dialog>
        </el-dialog>
<!--        <el-dialog-->
<!--                title="工序记录"-->
<!--                :visible.sync="showTrace"-->
<!--                width="30%"-->
<!--                :show-close="false"-->
<!--        >-->
<!--            <div class="p50 record-bg">-->
<!--                <div v-for="(item,index) in traceList" :key="index" class="df">-->
<!--                    <div class="flex-8 tc">-->
<!--                        <h1>{{item.name}}</h1>-->
<!--                    </div>-->
<!--                    <div v-if="item.type == 2" class="flex-16 tl">-->
<!--                        <h1>{{item.content}}</h1>-->
<!--                    </div>-->
<!--                    <div v-else-if="item.type == 1" class="flex-16 tl">-->
<!--                        <el-image-->
<!--                                v-for="(src,srcIndex) in JSON.parse(item.content)"-->
<!--                                :key="srcIndex"-->
<!--                                style="width: 200px; height: 200px"-->
<!--                                :src="src"-->
<!--                                fit="cover"-->
<!--                                :preview-src-list="JSON.parse(item.content)"-->
<!--                                class="mr10"-->
<!--                        ></el-image>-->
<!--                    </div>-->
<!--                    <div v-else-if="item.type == 4 && showTrace" class="flex-16 tl">-->
<!--                        <div v-for="(vidoe,vidoeIndex) in JSON.parse(item.content)" :key="vidoeIndex">-->
<!--                            <video Controls :poster="vidoe + '?x-oss-process=video/snapshot,t_1,m_fast'" class="video-js" style="height: 280px;width: 100%;margin-bottom: 5px">-->
<!--                                <source :src="vidoe" type="video/mp4"/>-->
<!--                            </video>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="showTrace = false">确 定</el-button>-->
<!--      </span>-->
<!--        </el-dialog>-->
        <div class="t-dialog" id="fade-box" v-show="showTrace">
            <div class="p50 record-bg">
                <div v-for="(item,index) in traceList" :key="index" class="df pr">
                    <div class="flex-8 tc col-fff f20 fw-b">
                        <h1>{{item.name}}</h1>
                    </div>
                    <div v-if="item.type == 2" class="flex-16 tl col-fff">
                        <h1>{{item.content}}</h1>
                    </div>
                    <div v-else-if="item.type == 1" class="flex-16 tl ">
                        <el-image
                                v-for="(src,srcIndex) in JSON.parse(item.content)"
                                :key="srcIndex"
                                style="width: 200px; height: 200px"
                                :src="src.url"
                                fit="cover"
                                :preview-src-list="JSON.parse(item.content).map(m => m.url)"
                                class="mr20 t-border mb10"
                        ></el-image>
                    </div>
                    <div v-else-if="(item.type == 4 || item.type == 3) && showTrace" class="flex-16 tl">
                        <div v-for="(vidoe,vidoeIndex) in JSON.parse(item.content)" :key="vidoeIndex">
                            <video Controls :poster="vidoe + '?x-oss-process=video/snapshot,t_1,m_fast'" class="video-js t-border mb10" style="height: 260px;width: 100%;margin-bottom: 5px">
                                <source :src="vidoe" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
                <div class="t-close" @click="closeTrace">
                    ×
                </div>
                <div class="t-title">
                    工序记录
                </div>
            </div>

        </div>
        <el-pagination
                background
                :page-size="10"
                :pager-count="5"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import api from "../../../util/extra-api";
    import bus from '@/util/bus'
    export default {
        data() {
            return {
                showTrace: false,
                traceList:[],
                list: [],
                controlData: null,
                samplingBatchData: null,
                rowId: null,
                clickRow: null,
                tableClickRow: null,
                timeValue: [],
                selectedPis: [],
                selectedPiInfo: [],
                selectedPiInfoCopy: [],
                start_time: "",
                end_time: "",
                traceUrl: "",
                dialogVisible: false,
                dialogTableVisible: false,
                innerVisible: false,
                tableFlag: true,
                compareFlag: false,
                form: {
                    control_info: "",
                    pi_id: null,
                },
                moveTemp: {
                    id: null,
                    weight: null,
                },
                dialogStatus: "create",
                processOptions: [],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "最近一周",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近一个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近三个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                    ],
                },
                listData: [{},{},{}],
                compareData: [],
                query:{
                    page: 1,
                    limit: 10,
                    companyId:'',
                    startTime: '',
                    endTime: ''
                },
                haccpTemplateId: null,
                total: 0
            };
        },
        computed: {
        },
        created() {},
        mounted() {
            this.query.companyId = localStorage.getItem("company_id")
            this.getList()
            bus.$on('clickHaccpBar',param =>{
                this.samplingBatchData = this.list[param.index].haccpChartData[param.dataIndex].recordChartVos
                this.haccpTemplateId = this.list[param.index].haccpChartData[param.dataIndex].id
                 this.dialogTableVisible = true
            })
        },
        methods: {
            handleCurrentChange(val){
                this.query.page = val
                this.getList()
            },
            getList(){
                if (this.timeValue && this.timeValue.length > 0) {
                    this.query.startTime = this.timeValue[0];
                    this.query.endTime = this.timeValue[1];
                }else{
                    this.query.startTime = '';
                    this.query.endTime = '';
                }
                api.get('/v1/pc/haccpScreen/chartData',this.query).then(res =>{
                    if(res.code === 200){
                        this.list = res.data
                        this.total = res.total
                    }
                })
            },


            toTrace(row) {
                let data = {
                    productionRecordId: row.id,
                    companyId: this.query.companyId,
                    haccpTemplateId: this.haccpTemplateId
                }
                api.get('/v1/pc/haccpScreen/submitData',data).then(res =>{
                    if(res.code === 200){
                        this.traceList = res.data
                        this.dialogTableVisible = false
                        this.showTrace = true
                    }
                })
            },
            closeTrace(){
                this.showTrace = false
                this.dialogTableVisible = true
            },

        },
    };
</script>
<style lang="scss" scoped>

    .record-bg{
        background-image: url("../../../assets/image/cool-background.png");
        overflow: hidden;
        border-radius: 20px;
        background-size: cover;
        z-index: 100;
        /*background-repeat: no-repeat;*/
    }
    .haccp-bg {
        transform-origin: 0px 0px 0px;
        background-image: url("../../../assets/image/mapbg.png");
        background-size: cover;
        background-position: 50% 0;
        background-color: rgb(0, 0, 0);
        min-width: auto;
        width: 100%;
        min-height: auto;
        height: 100%;
        overflow: hidden;
        overflow-x: hidden;
    }
    .search-box{
        padding: 10px;
        text-align: left;
    }
    $box-height: 520px;
    $box-width: 100%;
    #haccp-chart {
        padding: 20px 16px;
        height: $box-height;
        width: $box-width;
        border-radius: 5px;
        .bg-color-black {
            height: $box-height - 35px;
            border-radius: 10px;
        }
        .text {
            color: #c3cbde;
        }
        .chart-box {
            margin-top: 16px;
            width: 170px;
            height: 170px;
            .active-ring-name {
                padding-top: 10px;
            }
        }
    }
    .df{
        display: flex;
    }
    @for $i from 1 through 24 {
        .flex-#{$i} {
            flex: 1 round(percentage($i/24));
        }
    }
    .p50{
        padding:50px 20px!important;
        &>div{
            padding: 10px 0;
            border-top: 1px solid #7bbfea;
            display: flex;
            align-items: center;
        }
        &:last-child{
            padding:  0;
            border-bottom: 1px solid #7bbfea;
        }
    }
    .box-border{
        border: 1px solid #afdfe4;
        padding: 10px;
        border-radius: 15px;
        box-shadow: #afb4db 10px 10px 15px ;
    }
    $color: #2194E0;
    @keyframes sheen {
        0% {
            transform: skewY(-45deg) translateX(0);
        }
        100% {
            transform: skewY(-45deg) translateX(12.5em);
        }
    }
    .button {
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #33a3dc;
        border: 2px solid $color;
        font-size: 16px;
        display: inline-block;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-right: 10px;
        &:before {
            content: "";
            background-color: rgba(255,255,255,0.5);
            height: 100%;
            width: 3em;
            display: block;
            position: absolute;
            top: 0;
            left: -4.5em;
            transform: skewX(-45deg) translateX(0);
            transition: none;
        }
        &:hover {
            cursor:pointer;
            background-color: $color;
            color: #fff;
            border-bottom: 4px solid darken($color, 10%);
            &:before {
                transform: skewX(-45deg) translateX(13.5em);
                transition: all 0.5s ease-in-out;
            }
        }
    }
    $stark-light-blue: #00fffc;
    $stark-med-blue: #00a4a2;
    #fade-box {
        animation: form-entry 2s ease-in-out;
        /*background: #111;*/
        /*background: linear-gradient(#004746, #111);*/
        border: 1px solid $stark-med-blue;
        box-shadow: 0 0 15px #00fffd;
        border-radius: 20px;
        /*display: inline-block;*/
        /*height: 220px;*/
        /*margin: 200px auto 0;*/
        /*position: relative;*/
        z-index: 4;
        /*width: 500px;*/
        transition: 1s all ;

        &:hover {
            border: 1px solid #00fffd;
            box-shadow: 0 0 25px #00fffd;
            transition: 1s all ;
        }
    }
    @keyframes form-entry {
        0% {
            max-height: 80vh;
            width: 0;
            opacity: 0;
            padding: 0;
        }
        20% {
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            width: 0;
            opacity: 0;
            padding: 0;
        }


        40% {
            width: 0;
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            opacity: 1 ;
            padding: 0;
        }

        100% {
            max-height: 80vh;
            width: 800px;

        }
    }
    @keyframes input-entry {
        0% {
            opacity: 0;
        }
        90% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>
